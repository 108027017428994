<template>
  <div class="container">
    <eligibility-item
      v-if="currentPage == 0"
      :list="this.eligibility.questions"
      :condition="eligibilityConditions"
      :label="$t('lns.eligibility.label')"
      @output="setEligibilityValue($event)"
    ></eligibility-item>
    <!-- <terms-and-conditions
      v-if="currentPage == 1"
      @previousStep="previous()"
      @nextStep="next()"
      :termsAndConditions="$t('lns.termsAndConditions')"
    ></terms-and-conditions> -->
    <consent-item
      v-if="currentPage == 1"
      :consent="$t('lns.consents.list.0')"
      :name="'consent-one'"
      :label="$t('lns.consents.label')"
      :pageLabel="$t('lns.consents.pageLabel', { step: 1, total: 2 })"
      @previousStep="previous()"
      @nextStep="postConsent($event)"
    >
    </consent-item>
    <consent-item-v-1
      v-if="currentPage == 2"
      :consent="$t('lns.consents.list.1')"
      :name="'consent-two'"
      :label="$t('lns.consents.label')"
      :pageLabel="$t('lns.consents.pageLabel', { step: 2, total: 2 })"
      :lastPage="true"
      :lastPageConfirmation="$t('lns.consents.confirmationChoice')"
      @previousStep="previous()"
      @nextStep="postConsent($event)"
    ></consent-item-v-1>
    <!-- <consent-item v-if="currentPage == 3"
      :consent="$t('lns.consents.list.2')"
      :label="$t('lns.consents.label')"
      :pageLabel="$t('lns.consents.pageLabel', {step : 3, total : 3})"
      :lastPage="true"
      @previousStep="previous()"
      @nextStep="postConsent($event)"
    ></consent-item> -->
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="true"
    ></b-loading>
  </div>
</template>
<script>
// import TermsAndConditions from "@/core/components/ui/consent-agreement/TermsAndConditions.vue";
import ConsentItem from "@/core/components/ui/consent-agreement/ConsentItem.vue";
import EligibilityItem from "@/core/components/ui/consent-agreement/EligibilityItem.vue";
import consentService from "../../../../core/services/consent.service";
import store from "@/store";
import ConsentItemV1 from "@/core/components/ui/consent-agreement/ConsentItemV1.vue";
export default {
  name: "LnsConsent",
  components: {
    // TermsAndConditions,
    ConsentItem,
    EligibilityItem,
    ConsentItemV1,
  },
  data: () => ({
    participant_id: null,
    isConsentGiven: false,
    isLoading: false,
    currentPage: 0,
    eligibilityConditions: [
      { id: 1, value: "NO" },
      { id: 2, value: "NO" },
    ],
    eligibility: {
      title: "LNS COVID_19 CLINICAL TRIAL ELIGIBILITY ASSESSMENT",
      label: "COVID_19",
      type: "ELIGIBILITY",
      description:
        "LNS X VOICMED Clinical Trial Programme's participant's eligibility assessment",
      questions: [],
      langauge: store.state.locale,
      consented: true,
    },
    consent: {
      title: "LNS COVID_19 CLINICAL TRIAL CONSENT",
      label: "COVID_19",
      type: "GENERAL",
      description:
        "LNS X VOICMED Clinical Trial Programme's participant's consent",
      questions: [],
      langauge: store.state.locale,
      consented: true,
    },
  }),
  created() {
    //TODO: First try fetching from db if exists, if not fetch from i18n
    this.eligibility.questions = this.$t("lns.eligibility.list");
  },
  methods: {
    next() {
      if (this.currentPage >= 2) this.$emit("next");
      else this.currentPage++;
    },
    previous() {
      this.currentPage--;
    },
    setEligibilityValue() {
      this.isLoading = true;
      consentService
        .postTrialConsent(this.eligibility, "COVID_19")
        .then(() => {
          this.next();
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$buefy.dialog.alert("!Ops something went wrong");
        });
    },
    postConsent(value) {
      this.isLoading = true;
      const findIndex = this.consent.questions.findIndex(
        (v) => v.id == value.id
      );
      if (findIndex != -1) {
        this.consent.questions[findIndex] = value;
      } else {
        this.consent.questions.push(value);
      }
      if (this.currentPage == 2) {
        const lastPageConfirmation = this.$t(
          "lns.consents.confirmationChoice",
          "en"
        );
        const confirmationAsQuestion = {
          id: 3,
          question: lastPageConfirmation.label,
          tag: "UNDERSTAND_THE_SUBMISSION",
          answer: "YES",
          options: [
            { label: "Yes", value: "YES" },
            { label: "No", value: "NO" },
          ],
          errorMessage:
            "If you have questions, please contact the principal investigator of the study at the LNS Dr Amy Parrish T : +352 28 100 557, amy.parrish@lns.etat.lu",
        };
        this.consent.questions.push(confirmationAsQuestion);
      }

      consentService
        .postTrialConsent(this.consent, "COVID_19")
        .then(() => {
          this.isLoading = false;
          this.next();
        })
        .catch(() => {
          this.isLoading = false;
          this.$buefy.dialog.alert("!Ops something went wrong");
        });
    },
  },
};
</script>
<style scoped>
img {
  height: 100px;
}
.small-img {
  max-height: 400px;
}

.congrats-text {
  font-weight: bold;
  font-size: 30px;
  color: #ff4b7f !important;
}
.card {
  min-height: 520px;
  margin-bottom: 2rem;
}
.question-item {
  margin-top: 4rem;
}
.next-button:hover {
  color: #fff !important;
}
@media screen and (max-width: 576px) {
  .card-bottom {
    position: fixed;
    bottom: 0;
  }
  .next-button {
    min-width: 230px !important;
  }
}
</style>
