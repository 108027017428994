<template>
  <div class="card voicemed-card voicemed-card-centered-medium">
    <div class="question-section">
      <!-- enter here -->
      <div class="card-top card-content consent-info-card">
        <div class="text-description">
          <h2 class="is-size-4 title has-text-centered">{{ label }}</h2>
          <h3 class="is-size-7 title has-text-centered">{{ pageLabel }}</h3>
          <br />
        </div>
        <div class="pt-2">
          <img
            class="block-center"
            src="@/assets/media/img/patterns/consent.svg"
          />
        </div>
        <div class="has-text-centered pt-4">
          <form-confirmation-box
            :question="consent.question"
            :name="name + randName"
            :options="consent.options"
            @output="answer = $event"
          ></form-confirmation-box>
        </div>
        <div class="mt-4 px-3 py-2" v-if="lastPage">
          <checkbox-v-1
            :choice="lastPageConfirmation"
            @output="isConsentGiven = $event"
          ></checkbox-v-1>
        </div>
        <div class="pt-2 has-text-centered">
          <span class="error" v-if="answer == 'NO'">{{
            consent.errorMessage
          }}</span>
        </div>
      </div>
      <!-- enter here -->
      <!-- questions[currentQuestion].answer != 'Yes' -->
      <div class="card-bottom bottom w-full">
        <footer class="card-footer">
          <div class="card-footer-item">
            <b-button size="is-large mr-2" @click="previous()">
              <img src="/images/back.svg" />
            </b-button>
            <b-button
              size="is-large"
              v-if="!lastPage"
              class="voicemed-button next-button ml-2"
              :disabled="answer != 'YES'"
              @click.native="next()"
              >{{ $t("survey.buttons.next") }}</b-button
            >
            <b-button
              size="is-large"
              v-if="lastPage"
              class="voicemed-button next-button ml-2"
              :disabled="answer != 'YES' || !isConsentGiven"
              @click.native="next()"
              >{{ $t("buttons.submit") }}</b-button
            >
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import FormConfirmationBox from "@/core/components/ui/form-fields-ui/FormConfirmationBox";
import CheckboxV1 from "@/core/components/ui/form-fields-ui/CheckboxV1.vue";
import commonUtils from "@/core/plugins/utils";
import { mapActions } from "vuex";

export default {
  name: "ConsentItem",
  props: {
    name: { type: String, default: "consent-option" },
    label: { type: String },
    pageLabel: { type: String },
    lastPage: { type: Boolean, default: false },
    lastPageConfirmation: {
      type: Object,
      default: () => ({
        label:
          "I understand that by  clicking “Submit” I will be signing the informed consent form to participate in the COVID+VOICES study.",
        value: false,
        name: "consentConfirmation",
      }),
    },
    consent: {
      type: Object,
      default: () => ({
        question:
          "I voluntarily agree to participate in this study under the terms and conditions described before.",
        tag: "AGREE_TO_PARTICIPATE_CONSENT",
        answer: null,
        options: [
          {
            label: "Yes",
            value: "YES",
          },
          {
            label: "No",
            value: "NO",
          },
        ],
        errorMessage:
          "If you have questions, please contact the principal investigator of the study at the LNS Dr Amy Parrish T : +352 28 100 557, amy.parrish@lns.etat.lu",
      }),
    },
  },
  components: { FormConfirmationBox, CheckboxV1 },
  data: () => ({
    isConsentGiven: false,
    answer: null,
    currentQuestion: 0,
    randName: commonUtils.generateTimestampBasedUniqueID(),
  }),
  methods: {
    next() {
      this.consent.answer = this.answer;
      this.$emit("nextStep", this.consent);
    },
    previous() {
      this.$emit("previousStep");
    },
    ...mapActions(["changeConsentQuestionById"]),
  },
};
</script>
<style scoped>
img.block-center {
  height: 150px;
}

@media screen and (max-width: 576px) {
  .card-bottom {
    position: fixed;
    bottom: 0;
  }
  .next-button {
    min-width: 230px !important;
  }
}

@media all and (max-height: 789px) {
  .card-bottom {
    position: relative;
    margin-top: 5px;
    bottom: 0;
  }
}
</style>
