<template>
  <div class="main-wrapper py-2 px-4 p-lg-0 bg-lg-voicemed">
    <div class="cmp-logo has-text-centered py-4">
      <img
        src="@/assets/media/img/logo/voicemed-reversed.svg"
        alt=""
        srcset=""
      />
    </div>
    <div class="main columns" v-if="currentPage == 0">
      <lns-digital-consent
        @next="moveToPage(currentPage + 1)"
      ></lns-digital-consent>
    </div>
    <div class="main columns has-text-centered" v-if="currentPage == 1">
      <RecordingGuide
        @next="moveToPage(currentPage + 1)"
        :title="$t('lns.recordingGuide.title')"
        :lead="$t('lns.recordingGuide.lead')"
        :stepTitle="$t('lns.recordingGuide.stepTitle')"
        :instructions="$t('lns.recordingGuide.instructions')"
        :readyButton="
          isMobileScreen() ? $t('buttons.watchTutorial') : $t('buttons.letsGo')
        "
        :showVideo="true"
        :hideAnimation="true"
      ></RecordingGuide>
    </div>

    <div class="main columns has-text-centered" v-if="currentPage == 2">
      <CoughRecording
        :title="$t('lns.coughRecord.title')"
        :timerInstruction="$t('lns.coughRecord.timerInstruction')"
        :recordingTitle="$t('lns.coughRecord.recordingTitle')"
        :recordingSubtitle="$t('lns.coughRecord.recordingSubtitle')"
        :resultTitle="$t('lns.coughRecord.resultTitle')"
        :resultSubtitle="$t('lns.coughRecord.resultSubtitle')"
        :pageLabel="$t('lns.coughRecord.pageLabel', { step: 1, total: 3 })"
        @output="setAudio($event, 'cough')"
        @prev="moveToPage(currentPage - 1)"
        @next="moveToNextRecord(currentPage + 1, 'cough')"
        :showVideo="true"
        :hideAnimation="true"
      ></CoughRecording>
    </div>

    <div class="main columns has-text-centered" v-if="currentPage == 3">
      <BreathRecording
        :title="$t('lns.breathRecord.title')"
        :timerInstruction="$t('lns.breathRecord.timerInstruction')"
        :recordingTitle="$t('lns.breathRecord.recordingTitle')"
        :recordingSubtitle="$t('lns.breathRecord.recordingSubtitle')"
        :resultTitle="$t('lns.breathRecord.resultTitle')"
        :resultSubtitle="$t('lns.breathRecord.resultSubtitle')"
        :pageLabel="$t('lns.breathRecord.pageLabel', { step: 2, total: 3 })"
        @output="setAudio($event, 'breath')"
        @prev="moveToPage(currentPage - 1)"
        @next="moveToNextRecord(currentPage + 1, 'breath')"
        :showVideo="true"
        :hideAnimation="true"
      ></BreathRecording>
    </div>

    <div class="main columns has-text-centered" v-if="currentPage == 4">
      <SpeechRecording
        :title="$t('lns.speechRecord.title')"
        :timerInstruction="$t('lns.speechRecord.timerInstruction')"
        :recordingTitle="$t('lns.speechRecord.recordingTitle')"
        :recordingSubtitle="$t('lns.speechRecord.recordingSubtitle')"
        :resultTitle="$t('lns.speechRecord.resultTitle')"
        :resultSubtitle="$t('lns.speechRecord.resultSubtitle')"
        :pageLabel="$t('lns.speechRecord.pageLabel', { step: 3, total: 3 })"
        @output="setAudio($event, 'speech')"
        @prev="moveToPage(currentPage - 1)"
        @next="moveToNextRecord(currentPage + 1, 'speech')"
        :showVideo="true"
        :hideAnimation="true"
      ></SpeechRecording>
    </div>

    <div class="main columns has-text-centered" v-if="currentPage == 5">
      <transition-to-survey
        :headerOne="$t('lns.transitionToSurvey.headerOne')"
        :headerTwo="$t('lns.transitionToSurvey.headerTwo')"
        :infoMessage="$t('lns.transitionToSurvey.infoMessage')"
        :nextButtonLabel="$t('lns.transitionToSurvey.nextButtonLabel')"
        @next="moveToPage(currentPage + 1)"
      ></transition-to-survey>
    </div>

    <div class="main columns has-text-left" v-if="currentPage == 6">
      <div class="container">
        <question-section-v-1
          :questions="questions"
          :itemsPerPage="1"
          :setCurrentPage="currentQuestionPage"
          :questionTranslations="$t('lns.questions', { returnObjects: true })"
          @next="handleQuestionNextPage($event)"
          @previous="handleQuestionPrevPage($event)"
          @end="nextPageSection()"
        ></question-section-v-1>
      </div>
    </div>

    <div class="main columns" v-if="currentPage == 7">
      <clinical-trial-thank
        :exclamationMessage="$t('lns.thankYou.exclamationMessage')"
        :leadMessage="$t('lns.thankYou.leadMessage')"
        :infoMessage="$t('lns.thankYou.infoMessage')"
        :stayTunedButtonLabel="$t('lns.thankYou.stayTunedButtonLabel')"
        :exitLabel="$t('lns.thankYou.exitLabel')"
        :hideEmailButton="true"
        @next="moveToPage(currentPage + 1)"
      ></clinical-trial-thank>
    </div>

    <div class="main columns" v-if="currentPage == 8">
      <LnsEmailGathering
        :header="$t('lns.emailSection.header')"
        :lead="$t('lns.emailSection.lead')"
        :emailFormLabel="$t('lns.emailSection.emailFormLabel')"
        :sendMailLabel="$t('lns.emailSection.sendMailButtonLabel')"
        @done="submitGuestEmail($event)"
      ></LnsEmailGathering>
    </div>
    <div class="main columns" v-if="currentPage == 9">
      <LnsEmailSent
        :header="$t('lns.emailSentSection.header')"
        :lead="$t('lns.emailSentSection.lead')"
        :email="emailAfterSurvey"
        :closeWindowLabel="$t('lns.emailSentSection.closeWindowLabel')"
        @done="logout()"
      >
      </LnsEmailSent>
    </div>
  </div>
</template>
<script>
import CoughRecording from "@/core/components/ui/audio-ui/CoughRecording";
import BreathRecording from "@/core/components/ui/audio-ui/BreathRecording";
import SpeechRecording from "@/core/components/ui/audio-ui/SpeechRecording";
import RecordingGuide from "@/core/components/ui/guidelines-ui/RecordingGuide";
import LnsEmailGathering from "./_widgets/LnsEmailGathering";
import QuestionSectionV1 from "@/core/components/ui/questions-ui//vmd-trial-v1/QuestionSectionV1.vue";
import LnsDigitalConsent from "./_widgets/LnsDigitalConsent.vue";
import surveyService from "@/core/services/survey.service";
import { mapActions, mapGetters } from "vuex";
import TransitionToSurvey from "@/core/components/ui/extra-ui/TransitionToSurvey.vue";
import ClinicalTrialThank from "@/core/components/ui/extra-ui/ClinicalTrialThank.vue";
import LnsEmailSent from "./_widgets/LnsEmailSent.vue";

export default {
  name: "LnsTrial",
  components: {
    CoughRecording,
    BreathRecording,
    SpeechRecording,
    RecordingGuide,
    LnsEmailGathering,
    QuestionSectionV1,
    LnsDigitalConsent,
    TransitionToSurvey,
    LnsEmailSent,
    ClinicalTrialThank,
  },
  computed: {
    ...mapGetters(["getSurveyQuestions", "getSurveyCompletionPercentage"]),
  },
  created() {
    this.changeSurveyQuestions(this.$t("lns.questions", "en"));
    //ADD SURVEY QUESTION TRANSLATION
    this.changeSurveyQuestionsTranslation(this.$t("lns.questions"));
    this.changeTrialSurveyQuestionsAdvanced().then((resp) => {
      this.questions = resp.questions;
    });

    // Set Last Visited Page and section
    surveyService.getTrialPageStats().then((resp) => {
      const data = resp.data.data;
      this.currentQuestionPage = Number(data.last_visited_section);
      this.currentPage =
        Number(data.last_visited_page) >= this.pages.length - 1
          ? this.pageOfCompletion - 1
          : Number(data.last_visited_page);
    });
    this.questions = this.$t("lns.questions", "en");
  },
  data: () => ({
    isLoading: false,
    currentPage: 0,
    currentQuestionPage: 0,
    isConsentGiven: false,
    isRecording: false,
    emailAfterSurvey: null,
    pages: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    pageOfCompletion: 7,
    questionSectionPage: 6,
    questions: [],
    audio: {
      breath: null,
      speech: null,
      cough: null,
      phonation1: null,
      phonation2: null,
      phonation3: null,
    },
  }),
  methods: {
    ...mapActions([
      "postSurvey",
      "changeTrialSurveyQuestions",
      "changeTrialSurveyQuestionsAdvanced",
      "changeSurveyQuestions",
      "changeSurveyQuestionsTranslation",
    ]),
    isMobileScreen() {
      return window.screen.width <= 576;
    },
    moveToPage(num) {
      this.currentPage = num;
      // this.$router.replace({name: "LnsSurvey", query: { section: this.currentQuestionPage, page: num } }).catch(() => {})
      window.scrollTo({
        top: 5,
        behavior: "smooth",
      });
    },
    handleQuestionNextPage($event) {
      this.currentQuestionPage = $event.currentPage;
      // this.$router.replace({name: "LnsSurvey", query: { section: this.currentQuestionPage, page : this.currentPage } })
      this.submitQuestions();
    },
    handleQuestionPrevPage($event) {
      this.currentQuestionPage = $event.currentPage;
      // this.$router.replace({name: "LnsSurvey", query: { section: this.currentQuestionPage, page : this.currentPage } })
    },
    setAudio(value, recordType) {
      this.audio[recordType] = value;
    },
    submitQuestions() {
      const completedPerct =
        this.currentPage == this.pageOfCompletion
          ? 100
          : this.getSurveyCompletionPercentage;
      const payload = {
        title: "LNS X VOICED COVID-19 survery FOR CLINICAL TRIALS",
        description: "LNS X VOICEMED COVID 19 Survey for CLINICAL TRIALS",
        label: "COVID_19",
        questions: this.getSurveyQuestions,
        version: 3.2,
        completion_percentage: completedPerct,
        last_visited_page: this.currentQuestionPage,
        language: "en",
      };
      surveyService
        .postTrialSurvey(payload, {
          last_visited_page: this.currentPage,
          last_visited_section: this.currentQuestionPage,
        })
        .then(() => {});
    },
    // eslint-disable-next-line no-unused-vars
    moveToNextRecord(value, recordType) {
      this.submitSample(recordType).then(() => true);
      this.moveToPage(value);
    },
    // eslint-disable-next-line no-unused-vars
    submitSample(recordType) {
      return surveyService.postTrialSample(
        "COVID_19",
        recordType,
        this.audio[recordType],
        {
          last_visited_page: this.currentPage,
          last_visited_section: this.currentQuestionPage,
        }
      );
    },
    submitGuestEmail(email) {
      this.emailAfterSurvey = email;
      surveyService.postGuestEmail(email).then(() => true);
      this.moveToPage(this.currentPage + 1);
    },
    nextPageSection() {
      this.moveToPage(this.currentPage + 1);
      this.submitQuestions();
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.replace(`/${this.$store.state.locale}`).catch(() => {});
    },
  },
};
</script>
<style scoped></style>
