<template>
  <div class="card voicemed-card voicemed-card-centered-medium">
    <div class="question-section">
      <div class="card-top card-content consent-info-card has-text-centered">
        <h2 class="is-size-4 title has-text-centered my-5">
          {{ label }}
        </h2>
        <section class="item-content">
          <div class="mb-4" v-for="(quest, index) of list" :key="index">
            <form-confirmation-box
              :question="quest.question"
              :name="'elig-consent-' + index"
              :options="quest.options"
              @output="quest.answer = $event"
            ></form-confirmation-box>
          </div>
        </section>
      </div>
      <div class="card-bottom bottom w-full">
        <footer class="card-footer">
          <div class="card-footer-item">
            <b-button size="is-large mr-2" @click="previous()" :disabled="true">
              <img src="/images/back.svg" />
            </b-button>
            <b-button
              size="is-large"
              class="voicemed-button next-button ml-2"
              :disabled="!checkEligibity"
              @click.native="next()"
              >{{ $t("survey.buttons.next") }}</b-button
            >
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import { defaultConsent } from "@/core/services/consent.service";
import { mapActions, mapGetters } from "vuex";
import FormConfirmationBox from "@/core/components/ui/form-fields-ui/FormConfirmationBox";

export default {
  name: "EligibilityItem",
  components: { FormConfirmationBox },
  props: {
    name: { type: String },
    label: { type: String, default: "Eligibility self-assessment" },
    list: {
      type: Array,
      default: () => [
        {
          id: 1,
          question: "Are you older than 18 years?",
          answer: null,
          options: [
            { label: "Yes", value: "YES" },
            { label: "No", value: "NO" }
          ]
        },
        {
          id: 2,
          question:
            "have you already participated in this specific study before?",
          answer: null,
          options: [
            { label: "Yes", value: "YES" },
            { label: "No", value: "NO" }
          ]
        },
        {
          id: 3,
          question: "Do you have any severe visual or auditory impairment?",
          answer: null,
          options: [
            { label: "Yes", value: "YES" },
            { label: "No", value: "NO" }
          ]
        }
      ]
    },
    condition: {
      type: Array,
      default: () => [
        { id: 1, value: "YES" },
        { id: 2, value: "NO" },
        { id: 3, value: "NO" }
      ]
    }
  },
  data: () => ({
    isConsentGiven: false,
    questions: defaultConsent,
    dataList: [],
    dataCondition: []
  }),
  methods: {
    next() {
      this.questions.map(q => {
        this.changeConsentQuestionById({ id: q.id, questionItem: q });
      });
      // TODO Submit
      this.$emit("output", this.list);
      this.$emit("nextStep");
    },
    previous() {
      this.$emit("previousStep");
    },
    // checkEligibity(){
    //     if(this.condition != null && this.condition.length == this.list.length){
    //       const isEligible = this.list.every(v => {
    //         const selectedCondition = this.condition.filter(x => x.id == v.id)[0]
    //         return selectedCondition.value == v.value
    //       })
    //       console.log(isEligible)
    //       return isEligible
    //     }
    // },
    ...mapActions(["changeConsentQuestionById"])
  },
  computed: {
    ...mapGetters([]),
    checkEligibity: function() {
      if (this.condition != null && this.condition.length == this.list.length) {
        const isEligible = this.list.every(v => {
          const selectedCondition = this.condition.filter(x => x.id == v.id)[0];
          return selectedCondition.value == v.answer;
        });
        return isEligible;
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
.next-button {
  min-width: 230px !important;
}
.item-content {
  max-width: 400px;
  margin: 0 auto;
}
</style>
